import { RRule, rrulestr } from 'rrule';

export default function (scheduler) {

	function clearMilliseconds(date) {
		return new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
			0
		);
	}

	function isDeletedOccurrence(event) {
		return !!event.deleted;
	}

	function isSeries(event) {
		return !!event.rrule && !event.recurring_event_id;
	}

	function clearRecurringProperties(event) {
		event.rrule = "";
		event.original_start = null;
		event.recurring_event_id = null;
		event.duration = null;
		event.deleted = null;
	}

	function createException(ev) {
		let id = ev.id.split("#");

		let nid = scheduler.uid();
		scheduler._not_render = true;
		let nev = scheduler._copy_event(ev);
		nev.id = nid;
		nev.recurring_event_id = id[0];

		let timestamp = id[1];

		nev.original_start = new Date(Number(timestamp));

		scheduler._add_rec_marker(nev, timestamp);
		scheduler.addEvent(nev);
		scheduler._not_render = false;
	}

	function toUTCDate(date){
		return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(),date.getSeconds()));
	}

	function setUTCPartsToDate(d) {
		return new Date(
			d.getUTCFullYear(),
			d.getUTCMonth(),
			d.getUTCDate(),
			d.getUTCHours(),
			d.getUTCMinutes(),
			d.getUTCSeconds()
		);
	}

	function updateFollowingEventsRRULE(ev){
		if(ev.rrule.includes(";UNTIL=")){
			ev.rrule = ev.rrule.split(";UNTIL=")[0];
		}
		let parsedRRule = rrulestr(`RRULE:${ev.rrule};UNTIL=${toIcalString(ev._end_date || ev.end_date)}`, { dtstart: ev.start_date });
		let newRRULE = new RRule(parsedRRule.origOptions).toString().replace("RRULE:", "");
		newRRULE = newRRULE.split("\n")[1];
		ev.rrule = newRRULE;
	}
	// need to remove BYDAY from rrule to avoid incorrect checkboxes in lightbox
	function updateFollowingWithWEEKLY(id, ev){
		if(!ev){
			ev = scheduler.getEvent(id);
		}
		let rruleStringparts = ev.rrule.split(";");
		let updatedRRULE = [];
		for (let i = 0; i < rruleStringparts.length; i++) {
			let splited = rruleStringparts[i].split("=");
			let code = splited[0];
			let name = splited[1];
			if (code === "BYDAY") {
				continue;
			}
			updatedRRULE.push(code);
			updatedRRULE.push("=");
			updatedRRULE.push(name);
			updatedRRULE.push(";");
		}
		updatedRRULE.pop(); // delete last ;
		ev.rrule = updatedRRULE.join("");
	}

	scheduler._isFollowing = function(id){
		let ev = scheduler.getEvent(id);
		return !!(ev && ev._thisAndFollowing);
	};

	scheduler._isFirstOccurrence = function(ev){
		// check for first copy dummy
		if(scheduler._is_virtual_event(ev.id)){
			let pid = ev.id.split("#")[0];
			let recEvent = scheduler.getEvent(pid);
			return !!(recEvent.start_date.valueOf() === ev.start_date.valueOf());
		}
	};

	scheduler._isExceptionFirstOccurrence = function(ev){
		// check for first exception 
		if(scheduler._is_modified_occurrence(ev)){
			let pid = ev.recurring_event_id;
			let recEvent = scheduler.getEvent(pid);
			return !!(ev.original_start.valueOf() && ev.original_start.valueOf() === recEvent.start_date.valueOf());
		}
	};

	scheduler._rec_temp = [];
	scheduler._rec_markers_pull = {};
	scheduler._rec_markers = {};
	scheduler._add_rec_marker = function (ev, time) {
		ev._pid_time = time;
		this._rec_markers[ev.id] = ev;
		if (!this._rec_markers_pull[ev.event_pid]) this._rec_markers_pull[ev.event_pid] = {};
		this._rec_markers_pull[ev.event_pid][time] = ev;
	};
	scheduler._get_rec_marker = function (time, id) {
		let ch = this._rec_markers_pull[id];
		if (ch) return ch[time];
		return null;
	};
	scheduler._get_rec_markers = function (id) {
		return (this._rec_markers_pull[id] || []);
	};

	(function () {
		var old_add_event = scheduler.addEvent;
		scheduler.addEvent = function (start_date, end_date, text, id, extra_data) {
			var ev_id = old_add_event.apply(this, arguments);

			if (ev_id && scheduler.getEvent(ev_id)) {
				var ev = scheduler.getEvent(ev_id);

				if (ev.start_date) {
					ev.start_date = clearMilliseconds(ev.start_date);
				}
				if (ev.end_date) {
					ev.end_date = clearMilliseconds(ev.end_date);
				}
			}
			return ev_id;
		};
	})();

	scheduler.attachEvent("onEventLoading", function (event) {

		if (event.original_start && !event.original_start.getFullYear) {
			event.original_start = scheduler.templates.parse_date(event.original_start);
		}
		return true;
	});

	scheduler.attachEvent("onEventIdChange", function (id, new_id) {
		if (this._ignore_call) return;
		this._ignore_call = true;

		if (scheduler._rec_markers[id]) {
			//important for for correct work of scheduler.getEvents(from, to) and collision detection
			scheduler._rec_markers[new_id] = scheduler._rec_markers[id];
			delete scheduler._rec_markers[id];
		}

		if (scheduler._rec_markers_pull[id]) {
			scheduler._rec_markers_pull[new_id] = scheduler._rec_markers_pull[id];
			delete scheduler._rec_markers_pull[id];
		}

		for (var i = 0; i < this._rec_temp.length; i++) {
			var tev = this._rec_temp[i];
			if (this._is_virtual_event(tev.id) && tev.id.split("#")[0] == id) {
				tev.recurring_event_id = new_id;
				this.changeEventId(tev.id, new_id + "#" + tev.id.split("#")[1]);
			}
		}

		for (var i in this._rec_markers) {
			var tev = this._rec_markers[i];
			if (tev.recurring_event_id == id) {
				tev.recurring_event_id = new_id;
				tev._pid_changed = true;
			}
		}

		var el = scheduler._rec_markers[new_id];
		if (el && el._pid_changed) {
			delete el._pid_changed;
			setTimeout(function () {
				if (scheduler.$destroyed) {
					return true;
				}
				scheduler.callEvent("onEventChanged", [new_id, scheduler.getEvent(new_id)]);
			}, 1);
		}

		delete this._ignore_call;
	});
	function setPropsForFirstOccurrence(ev, occurrence){
		ev._end_date = ev.end_date;
		if (scheduler._isExceptionFirstOccurrence(occurrence)){
			ev.start_date = occurrence.start_date;
			ev.end_date = new Date(occurrence.start_date.valueOf() + ev.duration * 1000);
			ev._start_date = occurrence.original_start;
			ev._modified = true;
		} else {
			ev.end_date = new Date(occurrence.start_date.valueOf() + ev.duration * 1000);
			ev.start_date = occurrence.start_date;
			ev._firstOccurrence = true;
		}
		ev._thisAndFollowing = occurrence.id;
	}

	function setPropsForStorageEvent(index, data, ev, tempEvent) {
		//  index based on whether it's an exception
		const targetIndex = ev._modified ? tempEvent.id : index;

		scheduler._events[targetIndex] = {
			...tempEvent,
			text: data.text,
			duration: data.duration,
			start_date: data.start_date,
			rrule: data.rrule,
			end_date: tempEvent._end_date,
			_start_date: tempEvent.start_date,
			_thisAndFollowing: null,
			_end_date: null
		};
	
		// If it's an exception, delete the it from storage
		if (ev._modified) {
			delete scheduler._events[index];
		}
	
		scheduler.callEvent("onEventChanged", [scheduler._events[targetIndex].id, scheduler._events[targetIndex]]);
	}

	function deleteExceptionFromStorage(exception) {
		for (const i in scheduler._events) {
			let tev = scheduler._events[i];
			if(tev.id == exception.id){
				delete scheduler._events[i];
			}
		}
	}

	function updateTextEvents(id, data){
		for (let i in scheduler._events) {
			let tev = scheduler._events[i];
			if (tev.recurring_event_id == id || (scheduler._is_virtual_event(tev.id) && tev.id.split("#")[0] == id)) {
				tev.text = data.text;
				scheduler.updateEvent(tev.id);
			}
		}
	}

	function deleteEventFromSeries(idTimestamp, ev) {
		let id = idTimestamp;
		let originalStartTimestamp = new Date(ev.original_start).valueOf(); // GS-2643: in case if an exception has already came from backend
		idTimestamp = String(id).split("#") || ev._pid_time || originalStartTimestamp; // id can be number
		let nid = scheduler.uid();
		let tid = (idTimestamp[1]) ? idTimestamp[1] : (ev._pid_time || originalStartTimestamp);
		let nev = scheduler._copy_event(ev);
		nev.id = nid;
		nev.recurring_event_id = ev.recurring_event_id || idTimestamp[0];
		nev.original_start = new Date(Number(tid));
		nev.deleted = true;
		scheduler.addEvent(nev);
	}

	scheduler.attachEvent("onConfirmedBeforeEventDelete", function (id) {
		var ev = this.getEvent(id);
		if (this._is_virtual_event(id) || (this._is_modified_occurrence(ev) && !isDeletedOccurrence(ev))) {
			deleteEventFromSeries(id, ev);
		} else {
			if (isSeries(ev) && this._lightbox_id)
				this._roll_back_dates(ev);

			var sub = this._get_rec_markers(id);
			for (var i in sub) {
				if (sub.hasOwnProperty(i)) {
					id = sub[i].id;
					if (this.getEvent(id))
						this.deleteEvent(id, true);
				}
			}
		}
		return true;
	});

	scheduler.attachEvent("onEventDeleted", function (id, ev) {
		if (!this._is_virtual_event(id) && this._is_modified_occurrence(ev)) {
			if (!scheduler._events[id]) {
				ev.deleted = true;
				this.setEvent(id, ev);
				scheduler.render();
			}
		}
	});

	function removeTempDraggedEvent(){
		for (const i in scheduler._events) {
			if(i === "$dnd_recurring_placeholder"){
				delete scheduler._events[i];
			}
		}
		scheduler.render();
	}

	scheduler.attachEvent("onBeforeEventChanged", function(ev, e, is_new, original){
		if(!is_new && ev && (scheduler._is_virtual_event(ev.id) || scheduler._is_modified_occurrence(ev))){
			if (original.start_date.getDate() !== ev.start_date.getDate()){
				ev._beforeEventChangedFlag = "edit";
			} else {
				ev._beforeEventChangedFlag = "ask";
			}
			// check collision before create
			if (!scheduler.config.collision_limit || scheduler.checkCollision(ev)) {
				scheduler._events["$dnd_recurring_placeholder"] = scheduler._lame_clone(ev); // adding temp event to the event store
				scheduler._showRequiredModalBox(ev.id, ev._beforeEventChangedFlag);
				return false;
			}	
		}
		return true;
	});

	scheduler.attachEvent("onEventChanged", function (id, event) {
		if (this._loading) return true;

		let ev = this.getEvent(id);
		if (this._is_virtual_event(id)) {
			createException(ev);

		} else {
			if (ev.start_date) {
				ev.start_date = clearMilliseconds(ev.start_date);
			}
			if (ev.end_date) {
				ev.end_date = clearMilliseconds(ev.end_date);
			}

			if (isSeries(ev) && this._lightbox_id) {
				if(ev._removeFollowing || this._isFollowing(id)){
					ev._removeFollowing = null;
				} else {
					this._roll_back_dates(ev);
				}
			}

			var sub = this._get_rec_markers(id);
			for (var i in sub) {
				if (sub.hasOwnProperty(i)) {
					delete this._rec_markers[sub[i].id];
					this.deleteEvent(sub[i].id, true);
				}
			}
			delete this._rec_markers_pull[id];

			// it's possible that after editing event is no longer exists, in such case we need to remove _select_id flag
			var isEventFound = false;
			for (var k = 0; k < this._rendered.length; k++) {
				if (this._rendered[k].getAttribute(this.config.event_attribute) == id)
					isEventFound = true;
			}
			if (!isEventFound)
				this._select_id = null;
		}
		removeTempDraggedEvent();
		return true;
	});
	scheduler.attachEvent("onEventAdded", function (id) {
		if (!this._loading) {
			var ev = this.getEvent(id);
			if (isSeries(ev)) {
				this._roll_back_dates(ev);
			}
		}
		return true;
	});
	scheduler.attachEvent("onEventSave", function (id, data, is_new_event) {
		let ev = this.getEvent(id);
		let tempEvent = scheduler._lame_clone(ev); // copy of the event before it have been cut
		let tempDataRRULE = data.rrule; // copy for following events
		if (ev && isSeries(ev)){
			//GS-2578: FollowingEvents
			if(!is_new_event && (this._isFollowing(id))){
				// removing FollowingEvents
				if (ev._removeFollowing) {
					let occurrence = scheduler.getEvent(ev._thisAndFollowing);
					if (occurrence && (ev._firstOccurrence || ev._modified)) {
						scheduler.hideLightbox();
						scheduler.deleteEvent(ev.id);
						return false;
					} else {
						// cut off main rec event
						ev.end_date = new Date(ev.start_date.valueOf() - 1000);
						ev._end_date = ev._shorten_end_date;
						ev.start_date = ev._start_date;
						ev._shorten = true;
						updateFollowingEventsRRULE(ev);
						scheduler.callEvent("onEventChanged", [ev.id, ev]);
						// need to delete exceptions
						let occurrence = scheduler.getEvent(ev._thisAndFollowing);
						if (occurrence) {
							for (const i in scheduler._events) {
								let tev = scheduler._events[i];
								if (tev.recurring_event_id === id) {
									if (tev.start_date.valueOf() > tempEvent.start_date.valueOf()) {
										deleteEventFromSeries(tev.id, tev);
									}
								}
							}
						}
					}
					scheduler.hideLightbox();
					return false;
				} else {
					let occurrence = scheduler.getEvent(ev._thisAndFollowing);
					// case first occurence is a copy dummy
					if(occurrence && ev._firstOccurrence){
						for (const i in scheduler._events) {
							let tev = scheduler._events[i];
							if (tev.id == ev.id) {
								setPropsForStorageEvent(i, data, ev, tempEvent);
							}
						}
					// case first occurrence is a exception
					} else if (occurrence && ev._modified){
						for (const i in scheduler._events) {
							let tev = scheduler._events[i];
							if(tev.recurring_event_id == id && tev.id == tempEvent._thisAndFollowing){
								setPropsForStorageEvent(i, data, ev, tempEvent);
							}
						}
					} else {
						// case for exception
						if (scheduler._is_modified_occurrence(occurrence)){
							deleteExceptionFromStorage(occurrence);
						}
						// cut off main rec event
						ev.end_date = ev._shorten_end_date;
						ev._end_date = ev._shorten_end_date;
						ev.start_date = ev._start_date;
						ev._shorten = true;
						updateFollowingEventsRRULE(ev);
						scheduler.callEvent("onEventChanged", [ev.id, ev]);
						// creating FollowingEvents
						let followingEv = {...tempEvent};
						followingEv.text = data.text;
						followingEv.duration = data.duration;
						followingEv.rrule = tempDataRRULE;
						followingEv._start_date = null;
						followingEv.id = scheduler.uid();
						scheduler.addEvent(followingEv.start_date, followingEv.end_date, followingEv.text, followingEv.id, followingEv);
					}
					// if following events updates only text, we update text in all copy dummies and exceptions
					if (!is_new_event){
						updateTextEvents(id, data);
					}
					scheduler.hideLightbox();
					return false;
				}
			}
		}
		// if rec event updates only text, we update text in all copy dummies and exceptions
		if (!is_new_event){
			updateTextEvents(id, data);
		}
		// case for dragged occurence that is temp event
		if (tempEvent._ocr && tempEvent._beforeEventChangedFlag){
			ev.start_date = tempEvent.start_date; 
			ev.end_date = tempEvent.end_date; 
			ev._start_date = tempEvent._start_date;
			ev._end_date = tempEvent._end_date;
			scheduler.updateEvent(ev.id);
			return true;
		}
			this._select_id = null;
			removeTempDraggedEvent();
		return true;
	});
	scheduler.attachEvent("onEventCreated", function (id) {
		var ev = this.getEvent(id);
		if (!isSeries(ev)) {
			clearRecurringProperties(ev);
		}
		return true;
	});
	scheduler.attachEvent("onEventCancel", function (id) {
		var ev = this.getEvent(id);
		if (isSeries(ev)) {
			this._roll_back_dates(ev);
			// a bit expensive, but we need to be sure that event re-rendered, because view can be corrupted by resize , during edit process
			this.render_view_data();
		}
        removeTempDraggedEvent();
	});
	scheduler._roll_back_dates = function (ev) {
		//// !!!!! calc temp properties
		if (ev.start_date) {
			ev.start_date = clearMilliseconds(ev.start_date);
		}
		if (ev.end_date) {
			ev.end_date = clearMilliseconds(ev.end_date);
		}
		if (ev._end_date) {
			if (!ev._shorten){
				ev.duration = Math.round((ev.end_date.valueOf() - ev.start_date.valueOf()) / 1000);
			}
			ev.end_date = ev._end_date;
		}
		if (ev._start_date) {
			ev.start_date.setMonth(0);
			ev.start_date.setDate(ev._start_date.getDate());
			ev.start_date.setMonth(ev._start_date.getMonth());
			ev.start_date.setFullYear(ev._start_date.getFullYear());
			if (this._isFollowing(ev.id)){
				ev.start_date.setHours(ev._start_date.getHours());
				ev.start_date.setMinutes(ev._start_date.getMinutes());
				ev.start_date.setSeconds(ev._start_date.getSeconds());
			}
		}
		ev._thisAndFollowing = null;
		if(ev._shorten_end_date)
			ev._shorten_end_date = null;
		if(ev._removeFollowing)
			ev._removeFollowing = null;
		if(ev._firstOccurrence)
			ev._firstOccurrence = null;
		if(ev._modified)
			ev._modified = null;
	};

	scheduler._is_virtual_event = function (id) {
		return id.toString().indexOf("#") != -1;
	};
	scheduler._is_modified_occurrence = function (ev) {
		return (ev.recurring_event_id && ev.recurring_event_id != "0");
	};

	scheduler.showLightbox_rec = scheduler.showLightbox;
	scheduler.showLightbox = function (id) {
		const locale = this.locale;
		let formSetting = scheduler.config.lightbox_recurring;
		let ev = this.getEvent(id);
		let pid = ev.recurring_event_id;
		let isVirtual = this._is_virtual_event(id);
		if (isVirtual) {
			pid = id.split("#")[0];
		}

		const showRequiredLightbox = function (id, type) {
			const occurrence = scheduler.getEvent(id);
			const event = scheduler.getEvent(pid);
			const view = scheduler.getView();
			// for timeline and units
			if (view && occurrence[view.y_property]){
				event[view.y_property] = occurrence[view.y_property];
			}
			if (view && occurrence[view.property]){
				event[view.property] = occurrence[view.property];
			}

			if (type === "Occurrence") {
				return scheduler.showLightbox_rec(id);
			}

			if (type === "Following"){
				// case when  first occurrence is an exception
				if(scheduler._isExceptionFirstOccurrence(occurrence)){
					setPropsForFirstOccurrence(event, occurrence);
					return scheduler.showLightbox_rec(pid);
				}
				// for the first occurrence
				if (scheduler._isFirstOccurrence(occurrence)){
					setPropsForFirstOccurrence(event, occurrence);
					return scheduler.showLightbox_rec(pid);
				} else {
					// editing series
					event._end_date = event.end_date;
					const originalStart = occurrence.original_start || occurrence.start_date;
					event._shorten_end_date = new Date(originalStart.valueOf() - 1000);
					event.end_date = new Date(occurrence.start_date.valueOf() + event.duration * 1000);
					event._start_date = event.start_date;
					event.start_date = occurrence.start_date;
					event._thisAndFollowing = occurrence.id;

					// to indicate that occurence was created by drag
					if(ev._beforeEventChangedFlag){
						event._beforeEventChangedFlag = ev._beforeEventChangedFlag;
						event._shorten_end_date = new Date(originalStart.valueOf() - 1000);
					}

					return scheduler.showLightbox_rec(pid);
				}
			}

			if (type === "AllEvents"){
				if(scheduler._isExceptionFirstOccurrence(occurrence)){
					setPropsForFirstOccurrence(event, occurrence);
					return scheduler.showLightbox_rec(pid);
				}
				const tempStart = new Date(event.start_date);
				event._end_date = event.end_date;
				event._start_date = tempStart;
				event.start_date.setHours(occurrence.start_date.getHours());
				event.start_date.setMinutes(occurrence.start_date.getMinutes());
				event.start_date.setSeconds(occurrence.start_date.getSeconds());
				event.end_date = new Date(event.start_date.valueOf() + event.duration * 1000);
				event._thisAndFollowing = null;
				return scheduler.showLightbox_rec(pid);
			}
		};
		if ((pid || pid * 1 === 0) && isSeries(ev)) {
			// direct API call on series id
			return showRequiredLightbox(id, "AllEvents");
		}

		if (!pid || pid === '0' || ((!locale.labels.confirm_recurring || formSetting == 'instance') || (formSetting == 'series' && !isVirtual))) {
			// editing instance or non recurring event
			return this.showLightbox_rec(id);
		}

		if (formSetting === 'ask') {
			const locale = scheduler.locale;
			showModalbox([
				{value: "Occurrence", label: locale.labels.button_edit_occurrence, checked: true,
					callback: () => showRequiredLightbox(id, "Occurrence")},
				{value: "Following", label: locale.labels.button_edit_occurrence_and_following,
					callback: () => showRequiredLightbox(id, "Following")},
				{value: "AllEvents", label: locale.labels.button_edit_series,
					callback: () => showRequiredLightbox(id, "AllEvents")}
			]);
		}
	};

	function showModalbox(options, callback){
		const locale = scheduler.locale;
		const haveChecked = options.find(o => o.checked);
		if(!haveChecked){
			options[0].checked = true;
		}
		const callbacks = options.reduce((result, o) => {
			result[o.value] = o.callback;
			return result;
		}, {});
		scheduler.modalbox({
			text: `<div class="dhx_edit_recurrence_options">
				${options.map((option) => `<label class="dhx_styled_radio">
					<input type="radio" value="${option.value}" name="option" ${option.checked ? "checked" : ""}>
					${option.label}
				</label>`).join("")}
			</div>`,
			type: "recurring_mode",
			title: locale.labels.confirm_recurring,
			width: "auto",
			position: "middle",
			buttons: [
				{label: locale.labels.message_ok, value:"ok", css:"rec_ok"},
				{label: locale.labels.message_cancel, value: "cancel"}
			],
			callback: function (value, e) {
				if(callback){
					callback(value, e);
				}
				if(value === "cancel"){
					return;
				}
				const box = e.target.closest(".scheduler_modal_box");
				const checked = box.querySelector("input[type='radio']:checked");
				let selectedOption;
				if(checked){
					selectedOption = checked.value;
				}
				if(selectedOption){
					callbacks[selectedOption]();
				}
				
			}
		});
	}
	scheduler._showRequiredModalBox = function(id, type) {
		let buttons;

		const locale = scheduler.locale;
		let occurrence = scheduler.getEvent(id);
		let pid = occurrence.recurring_event_id;
		
		let isVirtual = scheduler._is_virtual_event(occurrence.id);
		if (isVirtual) {
			pid = occurrence.id.split("#")[0];
		}
		let event = scheduler.getEvent(pid);
		const view = scheduler.getView();
		let tempEvent = scheduler._lame_clone(event);
		// for timeline and units
		if (view && occurrence[view.y_property]){
			tempEvent[view.y_property] = occurrence[view.y_property];
		}
		if (view && occurrence[view.property]){
			tempEvent[view.property] = occurrence[view.property];
		}
		// for case with edit following events
		let tempStartDate;
		let tempEndDate;
		if (occurrence && occurrence._beforeEventChangedFlag){
			tempStartDate = occurrence.start_date;
			tempEndDate = occurrence.end_date;
		}
		const handleOccurrence = function(occurrence){
			let tempEvent = {...event, ...scheduler.getEvent("$dnd_recurring_placeholder")};
			if (tempEndDate && tempStartDate) {
				tempEvent.start_date = tempStartDate;
				tempEvent.end_date = tempEndDate;
				tempEvent._beforeEventChangedFlag = occurrence._beforeEventChangedFlag;
				tempEvent._ocr = true;
			}
			// check collision before create
			if (!scheduler.config.collision_limit || scheduler.checkCollision(tempEvent)) {
				for (const i in scheduler._events) {
					let tev = scheduler._events[i];
					if(i === "$dnd_recurring_placeholder"){
						continue;
					}
					if(tev.id == tempEvent.id){
						scheduler._events[i] = {...tempEvent};
						scheduler.callEvent("onEventChanged", [scheduler._events[i].id, scheduler._events[i]]);
					}
				}
			}
		};

		const handleFollowing = function(occurrence){
			let initialOccurrence = scheduler._lame_clone(occurrence);
			if (tempEndDate && tempStartDate) {
				occurrence._start_date = occurrence.start_date;
				occurrence.start_date = tempStartDate;
				occurrence.end_date = tempEndDate;
			}
			// for the first occurrence
			if (scheduler._isFirstOccurrence(initialOccurrence) || scheduler._isExceptionFirstOccurrence(initialOccurrence)){
				if(scheduler._isExceptionFirstOccurrence(initialOccurrence)){
					deleteExceptionFromStorage(initialOccurrence);
				}
				tempEvent._start_date = event.start_date;
				tempEvent.start_date = occurrence.start_date;
				tempEvent.duration = (+occurrence.end_date - +occurrence.start_date) / 1000;
				tempEvent._beforeEventChangedFlag = occurrence._beforeEventChangedFlag;
				// to avoid problem with weekly events
				if (tempEvent.rrule){
					updateFollowingWithWEEKLY(tempEvent.id, tempEvent);
				}
				// check collision before create
				if (!scheduler.config.collision_limit || scheduler.checkCollision(tempEvent)) {
					for (const i in scheduler._events) {
						let tev = scheduler._events[i];
						if(tev.id == tempEvent.id){
							scheduler._events[i] = {...tempEvent};
							scheduler.callEvent("onEventChanged", [scheduler._events[i].id, scheduler._events[i]]);
						}
					}
				}
			} else {
				// editing series
				tempEvent._end_date = event.end_date;
				const originalStart = occurrence.original_start || scheduler.date.date_part(new Date(occurrence._start_date));
				tempEvent._shorten_end_date = new Date(originalStart.valueOf() - 1000);
				tempEvent.end_date = occurrence.end_date;
				tempEvent._start_date = event.start_date;
				tempEvent.start_date = occurrence.start_date;
				tempEvent._thisAndFollowing = occurrence.id;
				// to avoid problem with weekly events
				if (tempEvent.rrule){
					updateFollowingWithWEEKLY(tempEvent.id, tempEvent);
				}

				// check collision before create
				let tempEnd = tempEvent.end_date;
				tempEvent.end_date = tempEvent._end_date;
				if (!scheduler.config.collision_limit || scheduler.checkCollision(tempEvent)) {
					tempEvent.end_date = tempEnd;
					for (const i in scheduler._events) {
						let tev = scheduler._events[i];
						if(tev.id == tempEvent.id){
							scheduler._events[i] = {...tempEvent};
							scheduler.callEvent("onEventSave", [scheduler._events[i].id, scheduler._events[i], scheduler._new_event]);
							scheduler.callEvent("onEventChanged", [scheduler._events[i].id, scheduler._events[i]]);
						}
					}
				}
			}
		};
		const handleAllEvents = function(occurrence){
			let initialOccurrence = scheduler._lame_clone(occurrence);
			if(scheduler._isExceptionFirstOccurrence(initialOccurrence)){
				deleteExceptionFromStorage(initialOccurrence);
			}
			if (tempEndDate && tempStartDate) {
				tempEvent.start_date.setHours(tempStartDate.getHours());
				tempEvent.start_date.setMinutes(tempStartDate.getMinutes());
				tempEvent.start_date.setSeconds(tempStartDate.getSeconds());
				tempEvent.duration = (+tempEndDate - +tempStartDate) / 1000;
			}
			tempEvent._beforeEventChangedFlag = occurrence._beforeEventChangedFlag;
			tempEvent._thisAndFollowing = null;
			// check collision before create
			if (!scheduler.config.collision_limit || scheduler.checkCollision(tempEvent)) {
				for (const i in scheduler._events) {
					let tev = scheduler._events[i];
					if(tev.id == tempEvent.id){
						scheduler._events[i] = {...tempEvent};
						scheduler.callEvent("onEventChanged", [scheduler._events[i].id, scheduler._events[i]]);
					}
				}
			}
		};
		
		const btnAll = {value: "AllEvents", label: locale.labels.button_edit_series, callback: () => handleAllEvents(occurrence)};
		const btnFollowing = {value: "Following", label: locale.labels.button_edit_occurrence_and_following, callback: () => handleFollowing(occurrence)};
		const btnOccurrence = {value: "Occurrence", label: locale.labels.button_edit_occurrence, callback: () => handleOccurrence(occurrence), checked: true};
		if (type === 'ask') {
			buttons = [
				btnOccurrence,
				btnFollowing,
				btnAll
			];
		} else { 
			buttons = [
				btnOccurrence,
				btnFollowing
			];
		}

		showModalbox(buttons, (result) => {
			if(result === "cancel"){
				removeTempDraggedEvent(); 
			}
		});
	};

	function groupExceptions() {
		const exceptions = {};
		for (const i in scheduler._events) {
			const ev = scheduler._events[i];
			if (ev.recurring_event_id && ev.original_start) {
				if (!exceptions[ev.recurring_event_id]) {
					exceptions[ev.recurring_event_id] = {};
				}
				exceptions[ev.recurring_event_id][ev.original_start.valueOf()] = ev;
			}
		}
		return exceptions;
	}
	function removeDraggedEventFromVisibleEvents(array) {
		const result = {};
		array.forEach(item => {
			const existingItem = result[item.id];
			// removing the existed event without `_beforeEventChangedFlag` property
			if (!existingItem || (existingItem._beforeEventChangedFlag || item._beforeEventChangedFlag)) {
				result[item.id] = item;
			}
		});
		return Object.values(result);
	}
	scheduler.get_visible_events_rec = scheduler.get_visible_events;
	scheduler.get_visible_events = function (only_timed) {
		for (var i = 0; i < this._rec_temp.length; i++)
			delete this._events[this._rec_temp[i].id];
		this._rec_temp = [];

		const exceptions = groupExceptions();
		var stack = this.get_visible_events_rec(only_timed);
		var out = [];
		for (var i = 0; i < stack.length; i++) {
			if (stack[i].deleted || stack[i].recurring_event_id) {
				continue;
			}

			if (isSeries(stack[i])) {
				this.repeat_date(stack[i], out, undefined, undefined, undefined, undefined, exceptions);
			} else {
				out.push(stack[i]);
			}
		}
		let result = removeDraggedEventFromVisibleEvents(out);
		return result;
	};

	(function () {
		var old = scheduler.isOneDayEvent;
		scheduler.isOneDayEvent = function (ev) {
			if (isSeries(ev)) return true;
			return old.call(this, ev);
		};
		var old_update_event = scheduler.updateEvent;
		scheduler.updateEvent = function (id) {
			var ev = scheduler.getEvent(id);

			if (ev && isSeries(ev) && !this._is_virtual_event(id)) {
				scheduler.update_view();
			} else {
				old_update_event.call(this, id);
			}
		};
	})();

	const toIcalString = scheduler.date.date_to_str("%Y%m%dT%H%i%s");

	scheduler.repeat_date = function (ev, stack, non_render, from, to, maxCount, exceptions) {
		if (!ev.rrule) {
			return;
		}

		let seriesExceptions = exceptions ? exceptions[ev.id] : groupExceptions()[ev.id];
		if (!seriesExceptions) {
			seriesExceptions = {};
		}

		// GS-2596: from and to should be in UTC,
		// need to remove one sec to not be included in repeatedDates array
		from = toUTCDate(from || new Date(scheduler._min_date.valueOf() - 1000));
		to = toUTCDate(to || new Date(scheduler._max_date.valueOf() - 1000));

		const utcStart = toUTCDate(ev.start_date);
		let parsedRRule;
		if (maxCount){
			// for getRecDates() method
			parsedRRule = rrulestr(`RRULE:${ev.rrule};UNTIL=${toIcalString(ev.end_date)};COUNT=${maxCount}`, { dtstart: utcStart });
		} else {
			parsedRRule = rrulestr(`RRULE:${ev.rrule};UNTIL=${toIcalString(ev.end_date)}`, { dtstart: utcStart });
		}

		const repeatedDates = parsedRRule.between(from, to, true).map(date => {
			const adjustedDate = setUTCPartsToDate(date);
			adjustedDate.setHours(ev.start_date.getHours());
			adjustedDate.setMinutes(ev.start_date.getMinutes());
			adjustedDate.setSeconds(ev.start_date.getSeconds());
			return adjustedDate;
		});
		let visibleCount = 0;

		const eventDuration = ev.duration;

		for (let i = 0; i < repeatedDates.length; i++) {
			if (maxCount && visibleCount >= maxCount) {
				break;
			}
			const date = repeatedDates[i];

			let exception = seriesExceptions[date.valueOf()];
			if (exception) {
				if (exception.deleted) {
					continue;
				} else {
					visibleCount++;
					stack.push(exception);
				}
			} else {
				const copy = scheduler._copy_event(ev);
				//copy._timed = ev._timed;
				copy.text = ev.text;
				copy.start_date = date;
				copy.id = ev.id + "#" + Math.ceil(date.valueOf());
				copy.end_date = new Date(date.valueOf() + eventDuration * 1000);
				copy.end_date = scheduler._fix_daylight_saving_date(copy.start_date, copy.end_date, ev, date, copy.end_date);
				copy._timed = scheduler.isOneDayEvent(copy);



				if (!copy._timed && !scheduler._table_view && !scheduler.config.multi_day) continue;
				stack.push(copy);

				if (!non_render) {
					scheduler._events[copy.id] = copy;
					scheduler._rec_temp.push(copy);
				}
				visibleCount++;
			}
		}
		//GS-2534: case when exceptions have different dates
		if (seriesExceptions && repeatedDates.length == 0 ){
			for (let a in seriesExceptions){
				let exception = seriesExceptions[a];
				if (exception) {
					if(exception.deleted){
						continue;
					}	else if (from && to && exception.start_date < to && exception.end_date > from) {
						stack.push(exception);
					}					
				} 
			}
		}
	};

	scheduler._fix_daylight_saving_date = function (start_date, end_date, ev, counter, default_date) {
		var shift = start_date.getTimezoneOffset() - end_date.getTimezoneOffset();
		if (shift) {
			if (shift > 0) {
				// e.g. 24h -> 23h
				return new Date(counter.valueOf() + ev.duration * 1000 - shift * 60 * 1000);
			}
			else {
				// e.g. 24h -> 25h
				return new Date(end_date.valueOf() - shift * 60 * 1000);
			}
		}
		return new Date(default_date.valueOf());
	};
	scheduler.getRecDates = function (id, max) {
		var ev = typeof id == "object" ? id : scheduler.getEvent(id);
		var recurrings = [];
		max = max || 100;

		if (!isSeries(ev)) {
			return [
				{ start_date: ev.start_date, end_date: ev.end_date }
			];
		}
		if (ev.deleted) {
			return [];
		}

		scheduler.repeat_date(ev, recurrings, true, ev.start_date, ev.end_date, max);

		var result = [];
		for (var i = 0; i < recurrings.length; i++) {
			if (!recurrings[i].deleted) {
				result.push({ start_date: recurrings[i].start_date, end_date: recurrings[i].end_date });
			}
		}

		return result;
	};
	scheduler.getEvents = function (from, to) {
		var result = [];
		const exceptions = groupExceptions();
		for (var a in this._events) {
			var ev = this._events[a];
			if (ev.recurring_event_id) {
				continue;
			}

			if (from && to && ev.start_date < to && ev.end_date > from) {
				if (isSeries(ev)) {
					var sev = [];
					this.repeat_date(ev, sev, true, from, to, undefined, exceptions);
					sev.forEach(function(occurrence){
						if (occurrence.start_date < to && occurrence.end_date > from) {
							result.push(occurrence);
						}
					});
				// if it's virtual event we can skip it
				} else if (!this._is_virtual_event(ev.id)) {
					result.push(ev);
				}
			} else if (!from && !to && !this._is_virtual_event(ev.id)) {
				result.push(ev);
			}
		}
		return result;
	};

	//drop secondary attributes
	scheduler._copy_dummy = function (ev) {
		var start_date = new Date(this.start_date);
		var end_date = new Date(this.end_date);
		this.start_date = start_date;
		this.end_date = end_date;
		this.duration = this.rrule = null;
	};

	scheduler.config.include_end_by = false;
	scheduler.config.lightbox_recurring = 'ask'; // cancel, instance, this and following, series
	scheduler.config.recurring_workdays = [RRule.MO.weekday,RRule.TU.weekday,RRule.WE.weekday,RRule.TH.weekday,RRule.FR.weekday];
	scheduler.config.repeat_date = "%m.%d.%Y";
	scheduler.config.lightbox.sections = [
		{ name: "description", map_to: "text", type: "textarea", focus: true },
		{ name: "recurring", type: "recurring", map_to: "rrule" },
		{ name: "time", height: 72, type: "time", map_to: "auto" }
	];

	scheduler.attachEvent("onClearAll", function () {
		scheduler._rec_markers = {}; //clear recurring events data
		scheduler._rec_markers_pull = {};
		scheduler._rec_temp = [];
	});


	function getTopLevelOption(rruleObj, untilDate) {
		const options = rruleObj.options;
		const until = options.until || untilDate;

		const hasEndCondition = options.count || (until && until.getFullYear() !== 9999);

		if (hasEndCondition) {
			return 'CUSTOM';
		}

		if (options.freq === RRule.DAILY && options.interval === 1 && !options.byweekday) {
			return 'DAILY';
		} else if (options.freq === RRule.WEEKLY && options.interval === 1 && !options.byweekday) {
			return 'WEEKLY';
		} else if (options.freq === RRule.MONTHLY && options.interval === 1 && !options.bysetpos) {
			return 'MONTHLY';
		} else if (options.freq === RRule.YEARLY && options.interval === 1 && !options.bysetpos) {
			return 'YEARLY';
		} else if (options.freq === RRule.DAILY && options.byweekday && options.byweekday.length === scheduler.config.recurring_workdays.length &&
			options.byweekday.includes(RRule.MO) &&
			options.byweekday.includes(RRule.TU) &&
			options.byweekday.includes(RRule.WE) &&
			options.byweekday.includes(RRule.TH) &&
			options.byweekday.includes(RRule.FR)) {
			return 'WORKDAYS';
		} else {
			return 'CUSTOM';
		}
	}
	
	function getWeekdayOfMonth(date) {
		const dayOfWeek = date.getDay();
		const dayOfMonth = date.getDate();
		const dayNumber = Math.ceil(dayOfMonth / 7);
		return { dayOfWeek, dayNumber };
	}


	const jsDaysToRRULEDays = {
		0: "SU",
		1: "MO",
		2: "TU",
		3: "WE",
		4: "TH",
		5: "FR",
		6: "SA"
	};
	const RruleDayNumsToJs = {
		0: 1,
		1: 2,
		2: 3,
		3: 4,
		4: 5,
		5: 6,
		6: 0
	};

	function fillCustomDaily(node, rule) {
		node.querySelector("[name='repeat_interval_value']").value = (rule ? rule.interval : 1) || 1;
	}

	function fillCustomWeekly(node, rule, event) {
		node.querySelector("[name='repeat_interval_value']").value = (rule ? rule.interval : 1) || 1;

		const dayCheckboxes = node.querySelectorAll(`.dhx_form_repeat_custom_week input`);
		dayCheckboxes.forEach((ch) => ch.checked = false);
		if (rule && rule.byweekday) {
			rule.byweekday.forEach((day) => {
				const dayNum = RruleDayNumsToJs[day.weekday];
				const dayLabel = jsDaysToRRULEDays[dayNum];
				node.querySelector(`.dhx_form_repeat_custom_week input[value="${dayLabel}"]`).checked = true;
			});
		} else {
			const dayLabel = jsDaysToRRULEDays[event.start_date.getDay()];
			node.querySelector(`.dhx_form_repeat_custom_week input[value="${dayLabel}"]`).checked = true;
		}

	}

	function fillCustomMonthly(node, rule, event) {
		node.querySelector("[name='repeat_interval_value']").value = (rule ? rule.interval : 1) || 1;

		const dateOfMonth = node.querySelector(`.dhx_form_repeat_custom_month [value="month_date"]`);
		const nthWeekDayOfMonth = node.querySelector(`.dhx_form_repeat_custom_month [value="month_nth_weekday"]`);
		dateOfMonth.innerText = scheduler.templates.repeat_monthly_date(event.start_date, event);
		nthWeekDayOfMonth.innerText = scheduler.templates.repeat_monthly_weekday(event.start_date, event);

		if (!rule || (rule.bysetpos && !(rule.byweekday && rule.byweekday.length))) {
			node.querySelector(`[name="dhx_custom_month_option"]`).value = "month_date";
		} else {
			node.querySelector(`[name="dhx_custom_month_option"]`).value = "month_nth_weekday";
		}
	}

	function formatDayNumber(date){
		switch (date) {
			case 1:
			case 31:
				return `${date}st`;
			case 2:
				return `${date}nd`;
			case 3:
				return `${date}rd`;
			default:
				return `${date}th`;
		}
	}

	scheduler.templates.repeat_monthly_date = function(startDate, event) {
		const date = startDate.getDate();
		return `Every ${formatDayNumber(date)}`;
	};
	scheduler.templates.repeat_monthly_weekday = function(startDate, event) {
		const nthDayOfMonth = getWeekdayOfMonth(startDate);
		return `Every ${formatDayNumber(nthDayOfMonth.dayNumber)} ${scheduler.locale.date.day_full[nthDayOfMonth.dayOfWeek]}`;
	};
	
	scheduler.templates.repeat_yearly_month_date = function(startDate, event) {
		const date = startDate.getDate();
		const monthLabel = scheduler.locale.date.month_full[startDate.getMonth()];
		return `Every ${formatDayNumber(date)} day of ${monthLabel}`;
	};
	scheduler.templates.repeat_yearly_month_weekday = function(startDate, event) {
		const nthDayOfMonth = getWeekdayOfMonth(startDate);
		const monthLabel = scheduler.locale.date.month_full[startDate.getMonth()];
		return `Every ${formatDayNumber(nthDayOfMonth.dayNumber)} ${scheduler.locale.date.day_full[nthDayOfMonth.dayOfWeek]} of ${monthLabel}`;
	};

	function fillCustomYearly(node, rule, event) {
		const dateOfYear = node.querySelector(`.dhx_form_repeat_custom_year [value="month_date"]`);
		const nthWeekDayOfYear = node.querySelector(`.dhx_form_repeat_custom_year [value="month_nth_weekday"]`);
		dateOfYear.innerText = scheduler.templates.repeat_yearly_month_date(event.start_date, event);
		nthWeekDayOfYear.innerText = scheduler.templates.repeat_yearly_month_weekday(event.start_date, event);

		if (!rule || (rule.bysetpos && !(rule.byweekday && rule.byweekday.length))) {
			node.querySelector(`[name="dhx_custom_year_option"]`).value = "month_date";
		} else {
			node.querySelector(`[name="dhx_custom_year_option"]`).value = "month_nth_weekday";
		}
	}

	function fillEndRule(node, rule, event) {
		const countInput = node.querySelector(`.dhx_form_repeat_ends_extra [name="dhx_form_repeat_ends_after"]`);
		const ondateInput = node.querySelector(`.dhx_form_repeat_ends_extra [name="dhx_form_repeat_ends_ondate"]`);
		const endOptionSelect = node.querySelector(`[name='dhx_custom_repeat_ends']`);
		countInput.value = 1;

		let formatter = scheduler.date.date_to_str("%Y-%m-%d");
		if (!scheduler.config.repeat_date_of_end) {
			scheduler.config.repeat_date_of_end = formatter(scheduler.date.add(scheduler._currentDate(), 30, "day"));
		}
		ondateInput.value = scheduler.config.repeat_date_of_end;

		if (rule && rule.count) {
			endOptionSelect.value = "AFTER";
			countInput.value = rule.count;
		} else if (event._end_date && event._end_date.getFullYear() !== 9999) {
			endOptionSelect.value = "ON";
			ondateInput.value = formatter(event._end_date);
		} else {
			endOptionSelect.value = "NEVER";
		}
		endOptionSelect.dispatchEvent(new Event("change"));
	}

	const getRecValue = {
		MONTHLY: function (dates) {
			const rrule = {
				freq: RRule.MONTHLY,
				interval: 1,
				bymonthday: dates.start.getDate()
			};
			const until = new Date(9999, 1, 1);
			return {rrule, until};
		},
		WEEKLY: function (dates) {
			let day = dates.start.getDay() - 1;
			if (day == -1) day = 6;
			const rrule = {
				freq: RRule.WEEKLY,
				interval: 1,
				byweekday: [day]
			};
			const until = new Date(9999, 1, 1);
			return {rrule, until};
		},
		DAILY: function (dates) {
			const rrule = {
				freq: RRule.DAILY,
				interval: 1
			};
			const until = new Date(9999, 1, 1);
			return {rrule, until};
		},
		YEARLY: function (dates) {
			const rrule = {
				freq: RRule.YEARLY,
				bymonth: dates.start.getMonth() + 1,
				interval: 1,
				bymonthday: dates.start.getDate()
			};
			const until = new Date(9999, 1, 1);
			return {rrule, until};
		},
		WORKDAYS: function (dates) {
			const rrule = {
				freq: RRule.WEEKLY,
				interval: 1,
				byweekday: scheduler.config.recurring_workdays
			};
			const until = new Date(9999, 1, 1);
			return {rrule, until};
		},
		CUSTOM: function (dates, node) {
			const rrule = {};
			const freq = node.querySelector(`[name="repeat_interval_unit"]`).value;
			const interval = Math.max(1, node.querySelector(`[name="repeat_interval_value"]`).value);
			const monthRepeat = node.querySelector(`[name="dhx_custom_month_option"]`).value;
			const yearRepeat = node.querySelector(`[name="dhx_custom_year_option"]`).value;

			rrule.interval = interval;
			let days;
			let day;

			switch (freq) {
				case "DAILY":
					rrule.freq = RRule.DAILY;
					break;
				case "WEEKLY": 
					rrule.freq = RRule.WEEKLY;
					days = [];
					node.querySelectorAll(`.dhx_form_repeat_custom_week [name="week_day"]`).forEach((ch) => {
						if(ch.checked){
							days.push(ch.value);
						}
					});

					rrule.byweekday = days.map((day) => {
						switch (day) {
							case "MO":
								return RRule.MO.weekday;
							case "TU":
								return RRule.TU.weekday;
							case "WE":
								return RRule.WE.weekday;
							case "TH":
								return RRule.TH.weekday;
							case "FR":
								return RRule.FR.weekday;
							case "SA":
								return RRule.SA.weekday;
							case "SU":
								return RRule.SU.weekday;
							default:
								break;
						}
					});
					break;
				case "MONTHLY":
					rrule.freq = RRule.MONTHLY;
					
					if (monthRepeat === "month_date") {
						rrule.bymonthday = dates.start.getDate();
					} else {
						day = dates.start.getDay() - 1;
						if (day == -1) day = 6;
						rrule.byweekday = [day];
						rrule.bysetpos = getWeekdayOfMonth(dates.start).dayNumber;
					}
					break;
				case "YEARLY":
					rrule.freq = RRule.YEARLY;
					rrule.bymonth = dates.start.getMonth() + 1;
					
					if (yearRepeat == "month_date") {
						rrule.bymonthday = dates.start.getDate();
					} else {
						day = dates.start.getDay() - 1;
						if (day == -1) day = 6;
						rrule.byweekday = [day];
						rrule.bysetpos = getWeekdayOfMonth(dates.start).dayNumber;
					}
					break;
			}
			const formatFunc = scheduler.date.str_to_date("%Y-%m-%d");
			let until = new Date(9999, 1, 1);
			const endRule = node.querySelector(`[name="dhx_custom_repeat_ends"]`);
			if(endRule.value === "ON"){
				until = formatFunc(node.querySelector(`[name="dhx_form_repeat_ends_ondate"]`).value);
				rrule.until = new Date(until);
			} else if(endRule.value === "AFTER") {
				rrule.count = Math.max(1, node.querySelector(`[name="dhx_form_repeat_ends_after"]`).value);
			}
			
			return {rrule, until};
		},
		NEVER: function () {
		}
	};

	function fillInDefaults(node, rule, event) {
		fillCustomDaily(node, rule, event);
		fillCustomWeekly(node, rule, event);
		fillCustomMonthly(node, rule, event);
		fillCustomYearly(node, rule, event);
		fillEndRule(node, rule, event);
	}
	scheduler.form_blocks["recurring"] = {
		render: function (sns) {
			if (sns.form) {
				let rec = scheduler.form_blocks["recurring"];
				let form = rec._get_node(sns.form);
				let html = rec._outer_html(form);
				form.style.display = 'none';
				return html;
			}
			let loc = scheduler.locale.labels;

			return `<div class="dhx_form_rrule">
		<div class="dhx_form_repeat_pattern">
			<select>
				<option value="NEVER">${loc.repeat_never}</option>
				<option value="DAILY">${loc.repeat_daily}</option>
				<option value="WEEKLY">${loc.repeat_weekly}</option>
				<option value="MONTHLY">${loc.repeat_monthly}</option>
				<option value="YEARLY">${loc.repeat_yearly}</option>
				<option value="WORKDAYS">${loc.repeat_workdays}</option>
				<option value="CUSTOM">${loc.repeat_custom}</option>
			</select>
		</div>
		<div class="dhx_form_repeat_custom dhx_hidden">
			<div class="dhx_form_repeat_custom_interval">
				<input name="repeat_interval_value" type="number" min="1">
				<select name="repeat_interval_unit">
					<option value="DAILY">${loc.repeat_freq_day}</option>
					<option value="WEEKLY">${loc.repeat_freq_week}</option>
					<option value="MONTHLY">${loc.repeat_freq_month}</option>
					<option value="YEARLY">${loc.repeat_freq_year}</option>
				</select>
			</div>

			<div class="dhx_form_repeat_custom_additional">
				<div class="dhx_form_repeat_custom_week dhx_hidden">
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="MO" />${loc.day_for_recurring[1]}</label>
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="TU" />${loc.day_for_recurring[2]}</label>
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="WE" />${loc.day_for_recurring[3]}</label>
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="TH" />${loc.day_for_recurring[4]}</label>
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="FR" />${loc.day_for_recurring[5]}</label>
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="SA" />${loc.day_for_recurring[6]}</label>
					<label><input class="dhx_repeat_checkbox" type="checkbox" name="week_day" value="SU" />${loc.day_for_recurring[0]}</label>
				</div>

				<div class="dhx_form_repeat_custom_month dhx_hidden">
					<select name="dhx_custom_month_option">
						<option value="month_date"></option>
						<option value="month_nth_weekday"></option>
					</select>
				</div>

				<div class="dhx_form_repeat_custom_year dhx_hidden">
					<select name="dhx_custom_year_option">
						<option value="month_date"></option>
						<option value="month_nth_weekday"></option>
					</select>
				</div>
			</div>

			<div class="dhx_form_repeat_ends">
				<div>${loc.repeat_ends}</div>
				<div class="dhx_form_repeat_ends_options">
					<select name="dhx_custom_repeat_ends">
						<option value="NEVER">${loc.repeat_never}</option>
						<option value="AFTER">${loc.repeat_radio_end2}</option>
						<option value="ON">${loc.repeat_on_date}</option>
					</select>
					<div class="dhx_form_repeat_ends_extra">
						<div class="dhx_form_repeat_ends_after dhx_hidden">
							<label><input type="number" min="1" name="dhx_form_repeat_ends_after">${loc.repeat_text_occurrences_count}</label>
						</div>
						<div class="dhx_form_repeat_ends_on dhx_hidden">
							<input type="date" name="dhx_form_repeat_ends_ondate">
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>`;
		},

	
		_init_set_value: function (node, value, event) {
			scheduler.form_blocks["recurring"]._ds = { start: event.start_date, end: event.end_date };
			function hide(node) {
				node.classList.add("dhx_hidden");
			}
			function show(node) {
				node.classList.remove("dhx_hidden");
			}
			function onRepeatOptionChange(value) {
				const repeat = node.querySelector(".dhx_form_repeat_custom");
				if (value === "CUSTOM") {
					show(repeat);
				} else {
					hide(repeat);
				}
			}

			function onCustomRepeatIntervalChange(value) {
				const nodes = {
					weekly: node.querySelector(".dhx_form_repeat_custom_week"),
					monthly: node.querySelector(".dhx_form_repeat_custom_month"),
					yearly: node.querySelector(".dhx_form_repeat_custom_year")
				};
				switch (value) {
					case "DAILY":
						hide(nodes.weekly);
						hide(nodes.monthly);
						hide(nodes.yearly);
						break;
					case "WEEKLY":
						show(nodes.weekly);
						hide(nodes.monthly);
						hide(nodes.yearly);
						break;
					case "MONTHLY":
						hide(nodes.weekly);
						show(nodes.monthly);
						hide(nodes.yearly);
						break;
					case "YEARLY":
						hide(nodes.weekly);
						hide(nodes.monthly);
						show(nodes.yearly);
						break;

				}
			}

			function onCustomRepeatEndRule(value) {
				const nodes = {
					after: node.querySelector(".dhx_form_repeat_ends_extra .dhx_form_repeat_ends_after"),
					on: node.querySelector(".dhx_form_repeat_ends_extra .dhx_form_repeat_ends_on")
				};
				switch (value) {
					case "NEVER":
						hide(nodes.after);
						hide(nodes.on);
						break;
					case "AFTER":
						show(nodes.after);
						hide(nodes.on);
						break;
					case "ON":
						hide(nodes.after);
						show(nodes.on);
						break;
				}
			}

			// repeat section change
			node.querySelector(".dhx_form_repeat_pattern select").addEventListener("change", function () {
				onRepeatOptionChange(this.value);
			});

			// custom repeat interval change
			node.querySelector(".dhx_form_repeat_custom_interval [name='repeat_interval_unit']").addEventListener("change", function () {
				onCustomRepeatIntervalChange(this.value);
			});

			// custom repeat end rule
			node.querySelector(".dhx_form_repeat_ends [name='dhx_custom_repeat_ends']").addEventListener("change", function () {
				onCustomRepeatEndRule(this.value);
			});

			scheduler._lightbox._rec_init_done = true;
		},
		button_click: function(){},
		set_value: function (node, value, ev) {
			let rf = scheduler.form_blocks["recurring"];
			if (!scheduler._lightbox._rec_init_done)
				rf._init_set_value(node, value, ev);
			node.open = !ev.rrule;

			node.blocked = this._is_modified_occurrence(ev);

			let ds = rf._ds;
			ds.start = ev.start_date;
			ds.end = ev._end_date;

			if (ev.rrule) {
				const rruleset = rrulestr(ev.rrule);
				fillInDefaults(node, rruleset.origOptions, ev);
				const topOption = getTopLevelOption(rruleset, ev._end_date);
				node.querySelector(".dhx_form_repeat_pattern select").value = topOption;
				if(topOption === "CUSTOM"){
					let customFreq;
					switch (rruleset.origOptions.freq) {
						case RRule.DAILY:
							customFreq = "DAILY";
							break;
						case RRule.WEEKLY:
							customFreq = "WEEKLY";
							break;
						case RRule.MONTHLY:
							customFreq = "MONTHLY";
							break;
						case RRule.YEARLY:
							customFreq = "YEARLY";
							break;
					}
					if(customFreq){
						node.querySelector(`[name="repeat_interval_unit"]`).value = customFreq;
						node.querySelector(`[name="repeat_interval_unit"]`).dispatchEvent(new Event("change"));
					}
				//	repeat_interval_unit
				}
				
			}else{
				fillInDefaults(node, null, ev);
				node.querySelector(".dhx_form_repeat_pattern select").value = "NEVER";
			}
			node.querySelector(".dhx_form_repeat_pattern select").dispatchEvent(new Event("change"));

		},
		get_value: function (node, ev) {
			
			if(!node.blocked && node.querySelector(".dhx_form_repeat_pattern select").value !== "NEVER") {
				let ds = scheduler.form_blocks["recurring"]._ds;
				let actual_dates = {};

				let timeControl = getTimeSection();

				timeControl.getValue(actual_dates);
				ds.start = actual_dates.start_date;
				const pattern = node.querySelector(".dhx_form_repeat_pattern select").value;
				const recurrence = getRecValue[pattern](ds, node);
				ev.rrule = new RRule(recurrence.rrule).toString().replace("RRULE:", "");
				ds.end = recurrence.until;

				ev.duration = Math.floor((actual_dates.end_date - actual_dates.start_date) / 1000);

				if (ds._start) {
					ev.start_date = new Date(ds.start);
					ev._start_date = new Date(ds.start);
					ds._start = false;
				} else
					ev._start_date = null;

				ev._end_date = ds.end;
			}else{
				ev.rrule = ev.rrule = "";
				ev._end_date = ev.end_date;
			}

			return ev.rrule;
		},

		focus: function (node) {
		}
	};

	function getTimeSection() {
		let timeControl = scheduler.formSection('time');
		if (!timeControl) {
			timeControl = getFirstSectionOfType('time');
		}
		if (!timeControl) {
			timeControl = getFirstSectionOfType('calendar_time');
		}


		if (!timeControl) {
			throw new Error(["Can't calculate the recurring rule, the Recurring form block can't find the Time control. Make sure you have the time control in 'scheduler.config.lightbox.sections' config.",
				"You can use either the default time control https://docs.dhtmlx.com/scheduler/time.html, or the datepicker https://docs.dhtmlx.com/scheduler/minicalendar.html, or a custom control. ",
				"In the latter case, make sure the control is named \"time\":",
				"",
				"scheduler.config.lightbox.sections = [",
				"{name:\"time\", height:72, type:\"YOU CONTROL\", map_to:\"auto\" }];"]
				.join("\n"));
		}

		return timeControl;
	}

	function getFirstSectionOfType(type) {
		for (let i = 0; i < scheduler.config.lightbox.sections.length; i++) {
			let section = scheduler.config.lightbox.sections[i];
			if (section.type === type) {
				return scheduler.formSection(section.name);
			}
		}
		return null;
	}

}