export default function extend(scheduler) {

	function isAttachedNode(container){
		if(container.isConnected !== undefined){
			return container.isConnected;
		}
		return document.body.contains(container);
	}
	
	function getWindowSize(window){
		return {
			w : window.innerWidth || document.documentElement.clientWidth,
			h : window.innerHeight || document.documentElement.clientHeight
		};
	}
	function equals(a,b){
		return a.w == b.w && a.h == b.h;
	}
	
	function listenWindowResize(scheduler, window){
		var oldSize = getWindowSize(window);
		var resizeDelay;
		scheduler.event(window, "resize", function(){
			clearTimeout(resizeDelay);
			resizeDelay = setTimeout(function(){

				if (!isAttachedNode(scheduler.$container) || scheduler.$destroyed){
					return;
				}
				var newSize = getWindowSize(window);
				// element may be resized by container-autoresize exteinsion
				// check if the size is actually changed in order to not to get endless loop
				if (!equals(oldSize, newSize)) {
					oldSize = newSize;
					triggerSchedulerResize(scheduler);
				}
			}, 150);
		});
	}
	
	function triggerSchedulerResize(scheduler){
		if(!scheduler.$initialized || scheduler.$destroyed || !scheduler.$root || !isAttachedNode(scheduler.$root)){
			return;	
		}
		if (scheduler.callEvent("onSchedulerResize", [])) {
			scheduler.updateView();
			scheduler.callEvent("onAfterSchedulerResize", []);
		}
	}
	
	function watchNodeResize(scheduler){
		var previousHeight = scheduler.$root.offsetHeight;
		var previousWidth = scheduler.$root.offsetWidth;
		
		function lowlevelResizeWatcher(){
			if(scheduler.$destroyed){
				return;	
			}
			
			if(scheduler.$root){
				if (scheduler.$root.offsetHeight != previousHeight ||
					scheduler.$root.offsetWidth != previousWidth){
					triggerSchedulerResize(scheduler);
				}
	
				previousHeight = scheduler.$root.offsetHeight;
				previousWidth = scheduler.$root.offsetWidth;
			}
	
			setTimeout(lowlevelResizeWatcher, 200);
		}
		
		lowlevelResizeWatcher();
	}
	
	function addResizeListener(scheduler){
		var root = scheduler.$container;
		var containerStyles = window.getComputedStyle(root);
		if(containerStyles.getPropertyValue("position") == "static"){
			root.style.position = "relative";
		}

		if(window.ResizeObserver){
			let skipFirst = true;// initial repaint triggers the resize listener
			const resizeObserver = new ResizeObserver(function(entries){
				if(skipFirst){
					skipFirst = false;
					return;
				}
				triggerSchedulerResize(scheduler);
			});
			resizeObserver.observe(root);
			scheduler.attachEvent("onDestroy", function(){
				resizeObserver.unobserve(root);
			});
		}else {
			var resizeWatcher = document.createElement('iframe');
			resizeWatcher.className = "scheduler_container_resize_watcher";
			resizeWatcher.tabIndex = -1;
			if(scheduler.config.wai_aria_attributes){
				resizeWatcher.setAttribute("role", "none");
				resizeWatcher.setAttribute("aria-hidden", true);
			}
		
			// in some environments (namely, in SalesForce) iframe.contentWindow is not available
			var salesforce_environment = !!window["Sfdc"] || !!window["$A"] || window["Aura"];
			if(salesforce_environment){
				watchNodeResize(scheduler);
			} else {
				root.appendChild(resizeWatcher);
				if (resizeWatcher.contentWindow) {
					listenWindowResize(scheduler, resizeWatcher.contentWindow);
				} else {
					// if so - ditch the iframe and fallback to listening the main window resize
					root.removeChild(resizeWatcher);
					listenWindowResize(scheduler, window);
				}
			}
		}
	

	}
	
	addResizeListener(scheduler);

}