function createHandlers(scheduler) {

	function remoteUpdates(message) {
		if (!message || !message.event || !message.event.id) {
			// eslint-disable-next-line no-console
			console.error("Invalid message format:", message);
			return;
		}

		const { type, event } = message;

		if (scheduler._dp._in_progress[event.id]) {
			return;
		}

		if (type === "add-event") {
			for (const id in scheduler._dp._in_progress) {
				if (scheduler._dp.getState(id) === "inserted") {
					scheduler._dp.attachEvent("onFullSync", function () {
						if (!scheduler.getEvent(event.id)) {
							processUpdate(type, event);
						}
					}, { once: true });
					return;
				}
			}
		}

		processUpdate(type, event);

	}

	function processUpdate(type, event) {
		switch (type) {
			case "add-event":
				handleAddEvent(event);
				break;
			case "update-event":
				handleUpdateEvent(event);
				break;
			case "delete-event":
				handleDeleteEvent(event);
				break;
			default:
				break;
		}
	}

	function ignore(code){
		if(scheduler._dp){
			scheduler._dp.ignore(code);
		}
		else {
			code();
		}
	}

	function handleAddEvent(eventData) {
		if (scheduler.getEvent(eventData.id)) {
			// eslint-disable-next-line no-console
			console.warn(`Event with ID ${eventData.id} already exists. Skipping add.`);
			return;
		}

		eventData.start_date = scheduler.templates.parse_date(eventData.start_date);
		eventData.end_date = scheduler.templates.parse_date(eventData.end_date);
		if (eventData.original_start) {
			eventData.original_start = scheduler.templates.parse_date(eventData.original_start);
		}

		ignore(() => {
			scheduler.addEvent(eventData);
		});

	}

	function handleUpdateEvent(eventData) {
		const sid = eventData.id;

		if (!scheduler.getEvent(sid)) {
			// eslint-disable-next-line no-console
			console.warn(`Event with ID ${sid} does not exist. Skipping update.`);
			return;
		}

		const existingEvent = scheduler.getEvent(sid);

		ignore(() => {
			for (let key in eventData) {
				if (key !== "start_date" && key !== "end_date") {
					existingEvent[key] = eventData[key];
				}
			}
			existingEvent.start_date = scheduler.templates.parse_date(eventData.start_date);
			existingEvent.end_date = scheduler.templates.parse_date(eventData.end_date);
			if (eventData.original_start) {
				eventData.original_start = scheduler.templates.parse_date(eventData.original_start);
			}
			scheduler.callEvent("onEventChanged", [sid, existingEvent]);
			scheduler.updateEvent(sid);

			if (sid !== eventData.id) {
				scheduler.changeEventId(sid, eventData.id);
			}
		});

	}

	function handleDeleteEvent(eventData) {
		const sid = eventData.id;

		if (!scheduler.getEvent(sid)) {

			if (eventData.event_pid) {
				ignore(() => {
					scheduler.addEvent(eventData);
				});
			}
			return;
		}

		ignore(() => {
			const event = scheduler.getEvent(sid);

			if (event) {
				// handle recurring event markers
				if (event.rec_type || event.rrule) {
					scheduler._roll_back_dates(event);

					const markers = scheduler._get_rec_markers(sid);
					for (const markerId in markers) {
						if (scheduler.getEvent(markerId)) {
							scheduler.deleteEvent(markerId, true);
						}
					}
				}

				// handle unsaved edits in the lightbox
				if (scheduler.getState().lightbox_id == sid) {
					this._new_event = this._lightbox_id;
					eventData.id = this._lightbox_id;
					this._events[this._lightbox_id] = eventData;
					if (scheduler.callEvent("onLiveUpdateCollision", [sid, null, "delete", eventData]) === false) {
						// abort deletion if collision handler prevents it
						scheduler.endLightbox(false, scheduler._lightbox);
						return;
					}
				}

				// delete the event
				scheduler.deleteEvent(sid, true);
			}

		});

	}

	return {
		events: remoteUpdates
	};
}

export default createHandlers;