export default {
	date: {
		month_full: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
		month_short: ["Jan", "Feb", "mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
		day_full: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"],
		day_short: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"]
	},
	labels: {
		dhx_cal_today_button: "Vandaag",
		day_tab: "Dag",
		week_tab: "Week",
		month_tab: "Maand",
		new_event: "Nieuw item",
		icon_save: "Opslaan",
		icon_cancel: "Annuleren",
		icon_details: "Details",
		icon_edit: "Bewerken",
		icon_delete: "Verwijderen",
		confirm_closing: "", //Your changes will be lost, are your sure ?
		confirm_deleting: "Item zal permanent worden verwijderd, doorgaan?",
		section_description: "Beschrijving",
		section_time: "Tijd periode",
		full_day: "Hele dag",

		confirm_recurring: "Wilt u alle terugkerende items bijwerken?",
		section_recurring: "Item herhalen",
		button_recurring: "Uit",
		button_recurring_open: "Aan",
		button_edit_series: "Bewerk de serie",
		button_edit_occurrence: "Bewerk een kopie",
		button_edit_occurrence_and_following: "This and following events",
		
		/*agenda view extension*/
		agenda_tab: "Agenda",
		date: "Datum",
		description: "Omschrijving",

		/*year view extension*/
		year_tab: "Jaar",

		/*week agenda view extension*/
		week_agenda_tab: "Agenda",

		/*grid view extension*/
		grid_tab: "Tabel",

		/* touch tooltip*/
		drag_to_create:"Drag to create",
		drag_to_move:"Drag to move",

		/* dhtmlx message default buttons */
		message_ok:"OK",
		message_cancel:"Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour:"Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Dagelijks",
		repeat_radio_week: "Wekelijks",
		repeat_radio_month: "Maandelijks",
		repeat_radio_year: "Jaarlijks",
		repeat_radio_day_type: "Elke",
		repeat_text_day_count: "dag(en)",
		repeat_radio_day_type2: "Elke werkdag",
		repeat_week: " Herhaal elke",
		repeat_text_week_count: "week op de volgende dagen:",
		repeat_radio_month_type: "Herhaal",
		repeat_radio_month_start: "Op",
		repeat_text_month_day: "dag iedere",
		repeat_text_month_count: "maanden",
		repeat_text_month_count2_before: "iedere",
		repeat_text_month_count2_after: "maanden",
		repeat_year_label: "Op",
		select_year_day2: "van",
		repeat_text_year_day: "dag",
		select_year_month: "maand",
		repeat_radio_end: "Geen eind datum",
		repeat_text_occurrences_count: "keren",
		repeat_radio_end3: "Eindigd per",
		repeat_radio_end2: "Na",

		repeat_never: "Nooit",
		repeat_daily: "Elke dag",
		repeat_workdays: "Elke werkdag",
		repeat_weekly: "Elke week",
		repeat_monthly: "Elke maand",
		repeat_yearly: "Elk jaar",
		repeat_custom: "Aangepast",
		repeat_freq_day: "Dag",
		repeat_freq_week: "Week",
		repeat_freq_month: "Maand",
		repeat_freq_year: "Jaar",
		repeat_on_date: "Op datum",
		repeat_ends: "Eindigt",

		month_for_recurring: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
		day_for_recurring: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"]
	}
};


