import RemoteEvents from "./remote_events";
import createHandlers from "./handlers";

export default function remoteEvents(scheduler) {
	if (!scheduler.ext){
		scheduler.ext = {};
	}

	scheduler.ext.liveUpdates = {
		RemoteEvents,
		remoteUpdates: createHandlers(scheduler)
	};
}

