export default function undoDelete(scheduler) {
	const undoableDeletes = {};
	scheduler.attachEvent("onConfirmedBeforeEventDelete", function (id) {
		undoableDeletes[id] = true;
		return true;
	});
	scheduler.attachEvent("onEventDeleted", function (id, ev) {
		if (undoableDeletes[id]) {
			delete undoableDeletes[id];
		} else {
			// ignore 'deleteEvent' calls called with 'silent' flag
			return;
		}
		let deletedEvent = scheduler.copy(ev);
		if (scheduler.config.undo_deleted && !scheduler.getState().new_event) {
			scheduler.message({
				text: `<div class="dhx_info_message">
                            <span class="undo_popup_text">Event deleted</span>
                            <button class="undo_button" data-deleted-event-id="${ev.id}">Undo</button>
                        </div>`,
				expire: 10000,
				type: "popup_after_delete",
				callback: function (e) {
					let undoBtn = e.target.closest(
						`[data-deleted-event-id="${ev.id}"]`
					);
					if (undoBtn) {
						// GS-2578: case for recurring events with occurences 
						if (deletedEvent.rrule && deletedEvent.duration) {
							deletedEvent.end_date = new Date(deletedEvent.start_date.valueOf() + deletedEvent.duration * 1000);
							scheduler.addEvent(deletedEvent);
						}
						scheduler.addEvent(deletedEvent);
						scheduler.render();
					}
				}
			});
		}
	});
}
