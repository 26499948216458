/*
 @Traducido por Vicente Adria Bohigues - vicenteadria@hotmail.com
 */
export default {
	date: {
		month_full: ["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Setembre", "Octubre", "Novembre", "Desembre"],
		month_short: ["Gen", "Feb", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Des"],
		day_full: ["Diumenge", "Dilluns", "Dimarts", "Dimecres", "Dijous", "Divendres", "Dissabte"],
		day_short: ["Dg", "Dl", "Dm", "Dc", "Dj", "Dv", "Ds"]
	},
	labels: {
		dhx_cal_today_button: "Hui",
		day_tab: "Dia",
		week_tab: "Setmana",
		month_tab: "Mes",
		new_event: "Nou esdeveniment",
		icon_save: "Guardar",
		icon_cancel: "Cancel·lar",
		icon_details: "Detalls",
		icon_edit: "Editar",
		icon_delete: "Esborrar",
		confirm_closing: "", //"Els seus canvis es perdràn, continuar ?"
		confirm_deleting: "L'esdeveniment s'esborrarà definitivament, continuar ?",
		section_description: "Descripció",
		section_time: "Periode de temps",
		full_day: "Tot el dia",

		confirm_recurring: "¿Desitja modificar el conjunt d'esdeveniments repetits?",
		section_recurring: "Repeteixca l'esdeveniment",
		button_recurring: "Impedit",
		button_recurring_open: "Permés",
		button_edit_series: "Edit sèrie",
		button_edit_occurrence: "Edita Instància",
		button_edit_occurrence_and_following: "This and following events",
		
		/*agenda view extension*/
		agenda_tab: "Agenda",
		date: "Data",
		description: "Descripció",

		/*year view extension*/
		year_tab: "Any",

		/*week agenda view extension*/
		week_agenda_tab: "Agenda",

		/*grid view extension*/
		grid_tab: "Taula",

		/* touch tooltip*/
		drag_to_create: "Drag to create",
		drag_to_move: "Drag to move",

		/* dhtmlx message default buttons */
		message_ok: "OK",
		message_cancel: "Cancel",

		/* wai aria labels for non-text controls */
		next: "Next",
		prev: "Previous",
		year: "Year",
		month: "Month",
		day: "Day",
		hour: "Hour",
		minute: "Minute",

		/* recurring event components */
		repeat_radio_day: "Diari", //name="repeat" value="day"
		repeat_radio_week: "Setmanal", //name="repeat" value="week
		repeat_radio_month: "Mensual",
		repeat_radio_year: "Anual",
		repeat_radio_day_type: "Cada",
		repeat_text_day_count: "dia",
		repeat_radio_day_type2: "Cada dia laborable",
		repeat_week: " Repetir cada",
		repeat_text_week_count: "setmana els dies següents:",
		repeat_radio_month_type: "Repetir",
		repeat_radio_month_start: "El",
		repeat_text_month_day: "dia cada",
		repeat_text_month_count: "mes",
		repeat_text_month_count2_before: "cada",
		repeat_text_month_count2_after: "mes",
		repeat_year_label: "El",
		select_year_day2: "de",
		repeat_text_year_day: "dia",
		select_year_month: "mes",
		repeat_radio_end: "Sense data de finalització",
		repeat_text_occurrences_count: "ocurrències",
		repeat_radio_end2: "Després",
		repeat_radio_end3: "Finalitzar el",

		repeat_never: "Mai",
		repeat_daily: "Cada dia",
		repeat_workdays: "Cada dia laborable",
		repeat_weekly: "Cada setmana",
		repeat_monthly: "Cada mes",
		repeat_yearly: "Cada any",
		repeat_custom: "Personalitzat",
		repeat_freq_day: "Dia",
		repeat_freq_week: "Setmana",
		repeat_freq_month: "Mes",
		repeat_freq_year: "Any",
		repeat_on_date: "En la data",
		repeat_ends: "Finalitza",

		month_for_recurring: ["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Setembre", "Octubre", "Novembre", "Desembre"],
		day_for_recurring: ["Diumenge", "Dilluns", "Dimarts", "Dimecres", "Dijous", "Divendres", "Dissabte"]
	}
};